import React from "react";
import { ApolloProvider } from "@apollo/client";
import { GraphQlClient } from "./graphql/client";
import { Modal, Select, Spin, Space, Button, PageHeader, Divider } from "antd";
import { DownOutlined } from "@ant-design/icons";
import CreateHcp from "./components/CreateHcp";
import CreateHcf from "./components/CreateHcf";
import CreateHcfsAndShifts from "./components/CreateHcfsAndShifts";
import ShiftAction from "./ShiftAction";
import { isLoggedIn } from "./common/authService";
import "./css/index.css";
import ShouldRender from "./components/ShouldRender";
import AssignShift from "./components/AssignShift";
import RunService from "./components/RunService";
import EnableStripe from "./components/EnableStripe";
import PaymentAnalytics from "./components/PaymentAnalytics";
import HCPClockOut from "./components/HCPClockOut";
import UploadDocs from "./components/UploadDocs";
import ApproveDocs from "./components/ApproveDocs";
import DownloadCSV from "./components/DownloadInvoiceCSV";
import { isDefined } from "@clipboard-health/util-ts";
import { showErrorToast } from "@src/appV2/lib/Notifications";

export function TestPanel() {
  const [panelVisible, setPanelVisible] = React.useState(false);
  const [selectedState, setSelectedState] = React.useState("");

  /**
   * clears indexDB, local storage, cache and cache
   */
  const handleClearSiteData = async (reload = true) => {
    try {
      // clear local storage
      localStorage.clear();
      // clear session storage
      sessionStorage.clear();
      // clear cookies
      const allCookies = document.cookie.split(";");
      for (let i = 0; i < allCookies.length; i++) {
        const key = allCookies[i].split("=");
        document.cookie = `${key[0]} =; expires = Thu, 01 Jan 1970 00:00:00 UTC`;
      }
      // clear indexed db
      const databases = await indexedDB.databases();
      databases.forEach((db) => {
        if (isDefined(db.name)) {
          indexedDB.deleteDatabase(db.name);
        }
      });

      if (reload) {
        location.reload();
      }
    } catch (error) {
      showErrorToast("An error occurred while clearing storage");
      console.error(error);
    }
  };

  return (
    <>
      <ApolloProvider client={GraphQlClient}>
        <ShouldRender condition={panelVisible}>
          <div
            className="expandedPanel"
            style={{
              height: isLoggedIn() ? "auto" : undefined,
              maxHeight: `100vh`,
              overflowY: isLoggedIn() ? "auto" : undefined,
            }}
          >
            <PageHeader
              className="testPanelHeader"
              title="Test Panel"
              extra={
                isLoggedIn() ? (
                  <>
                    <Button type="primary" onClick={() => handleClearSiteData(true)}>
                      Clear site data
                    </Button>

                    <DownOutlined onClick={() => setPanelVisible(false)} />
                  </>
                ) : (
                  <>
                    <Button type="primary" onClick={() => handleClearSiteData(true)}>
                      Clear site data
                    </Button>

                    <DownOutlined onClick={() => setPanelVisible(false)} />
                  </>
                )
              }
            />

            <Divider className="headerDivider" />

            <Spin spinning={false} style={{ width: "100%" }}>
              <Space direction="vertical" style={{ width: "100%" }}>
                {
                  <div>
                    <Select
                      placeholder="Set state..."
                      style={{ width: "300px" }}
                      onChange={(val) => setSelectedState(val)}
                      allowClear
                      data-testid="test-panel-action-switcher"
                    >
                      <Select.Option value="hcp">Create HCP</Select.Option>
                      <Select.Option value="hcf">Create HCF</Select.Option>
                      <Select.Option value="createHcfsAndShifts">
                        Create Workplaces and Shifts
                      </Select.Option>
                      <Select.Option value="enableStripe">Enable Stripe</Select.Option>
                      <Select.Option value="paymentAnalytics">Payment Analytics</Select.Option>
                      <Select.Option value="uploadDocs">Upload Required Documents</Select.Option>
                      <Select.Option value="approveDocs">Approve Pending Documents</Select.Option>
                      <Select.Option value="shift">Shift Action</Select.Option>
                      <Select.Option value="shiftAssign">Assign Shift</Select.Option>
                      <Select.Option value="runService">Run Event Service</Select.Option>
                      <Select.Option value="csv">Download Invoice CSV</Select.Option>
                      <Select.Option value="hcpClockOut">HCP Clock Out</Select.Option>
                      <Select.Option value="hideTP">Hide Test Panel for 1h</Select.Option>
                    </Select>
                  </div>
                }
              </Space>
            </Spin>
          </div>

          <Modal
            destroyOnClose
            centered
            footer={null}
            visible={selectedState === "hcp"}
            maskClosable
            onCancel={() => setSelectedState("")}
            className="hcpModal"
          >
            <CreateHcp />
          </Modal>

          <Modal
            destroyOnClose
            centered
            footer={null}
            visible={selectedState === "hcf"}
            maskClosable
            onCancel={() => setSelectedState("")}
            className="hcfModal"
          >
            <CreateHcf />
          </Modal>

          <Modal
            destroyOnClose
            centered
            footer={null}
            visible={selectedState === "createHcfsAndShifts"}
            maskClosable
            onCancel={() => setSelectedState("")}
            className="hcfModal"
          >
            <CreateHcfsAndShifts />
          </Modal>

          <Modal
            destroyOnClose
            centered
            footer={null}
            visible={selectedState === "shift"}
            maskClosable
            onCancel={() => setSelectedState("")}
            className="shiftActionModal"
          >
            <ShiftAction />
          </Modal>

          <Modal
            destroyOnClose
            centered
            footer={null}
            visible={selectedState === "hcpClockOut"}
            maskClosable
            onCancel={() => setSelectedState("")}
            className="shiftActionModal"
          >
            <HCPClockOut />
          </Modal>

          <Modal
            destroyOnClose
            centered
            footer={null}
            visible={selectedState === "shiftAssign"}
            maskClosable
            onCancel={() => setSelectedState("")}
            className="shiftAssign"
          >
            <AssignShift />
          </Modal>
          <Modal
            destroyOnClose
            centered
            footer={null}
            visible={selectedState === "enableStripe"}
            maskClosable
            onCancel={() => setSelectedState("")}
            className="enableStripeModal"
          >
            <EnableStripe />
          </Modal>
          <Modal
            destroyOnClose
            centered
            footer={null}
            visible={selectedState === "paymentAnalytics"}
            maskClosable
            onCancel={() => setSelectedState("")}
            className="enableStripeModal"
          >
            <PaymentAnalytics />
          </Modal>
          <Modal
            destroyOnClose
            centered
            footer={null}
            visible={selectedState === "runService"}
            maskClosable
            onCancel={() => setSelectedState("")}
            className="runService"
          >
            <RunService />
          </Modal>
          <Modal
            destroyOnClose
            centered
            footer={null}
            visible={selectedState === "uploadDocs"}
            maskClosable
            onCancel={() => setSelectedState("")}
            className="uploadDocs"
          >
            <UploadDocs />
          </Modal>
          <Modal
            destroyOnClose
            centered
            footer={null}
            visible={selectedState === "approveDocs"}
            maskClosable
            onCancel={() => setSelectedState("")}
            className="uploadDocs"
          >
            <ApproveDocs />
          </Modal>
          <Modal
            destroyOnClose
            centered
            footer={null}
            visible={selectedState === "csv"}
            maskClosable
            onCancel={() => setSelectedState("")}
          >
            <DownloadCSV />
          </Modal>
        </ShouldRender>
        <ShouldRender condition={!panelVisible}>
          <div className="minimizedPanel">
            <h2
              className="title"
              id={"authorize_button"}
              onClick={(e) => {
                setPanelVisible(true);
              }}
            >
              TP
            </h2>
          </div>
        </ShouldRender>
      </ApolloProvider>
    </>
  );
}
